// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/presupuestos`;

class PresupuestoService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    verPresupuestoDoc(id) {
        return axiosInstance.get(`${API_URL}/${id}/ver`);
    }

    guardar(presupuesto) {
        return axiosInstance.post(API_URL, presupuesto);
    }

    actualizar(id, presupuesto) {
        return axiosInstance.put(`${API_URL}/${id}`, presupuesto);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }
}

export default new PresupuestoService();