import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReporteService from '../services/ReporteService';
import {List, Typography, Button, message} from 'antd';

const {Title} = Typography;

const ReporteList = () => {
    const [reportes, setReportes] = useState([]);

    useEffect(() => {
        ReporteService.obtenerTodos().then(response => {
            setReportes(response.data);
        }).catch(error => {
            message.error('Error fetching reportes:', error);
            message.error('Error al obtener la lista de reportes');
        });
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Personal</Title>
            <List
                bordered
                dataSource={reportes}
                renderItem={reporte => (
                    <List.Item>
                        <Link to={`/reportes/${reporte.id}`}>{reporte.fechaReporte} - {reporte.descripcion}</Link>
                    </List.Item>
                )}
            />
            <div style={{marginTop: '1rem'}}>
                <Link to="/reportes/nuevo">
                    <Button type="primary">Nuevo reporte</Button>
                </Link>
            </div>
        </div>
    );
}

export default ReporteList;
