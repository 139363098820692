// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/alquileres`;

class AlquilerService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(alquiler) {
        return axiosInstance.post(API_URL, alquiler);
    }

    actualizar(id, alquiler) {
        return axiosInstance.put(`${API_URL}/${id}`, alquiler);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    obtenerFacturas(id){
        return axiosInstance.get(`${API_URL}/${id}/facturas`);
    }

    verContrato(id, contratoId) {
        return axiosInstance.get(`${API_URL}/${id}/contrato/${contratoId}/ver`);
    }
}

export default new AlquilerService();
