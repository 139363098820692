import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, message, Typography } from 'antd';
import ClienteService from '../services/ClienteService';
import EmisorService from '../services/EmisorService';

const { Title } = Typography;

const ClienteForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [cliente, setCliente] = useState({ id:'', nombre: '', cuit: '', direccion: '', telefono: '', email: '' });

    useEffect(() => {

        if (id) {
            ClienteService.obtenerPorId(id).then(response => {
                setCliente(response.data);
            }).catch(error => {
                message.error('Error fetching cliente:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        const clienteData = {
            id: cliente.id,
            nombre: cliente.nombre,
            cuit: cliente.cuit,
            direccion: cliente.direccion,
            telefono: cliente.telefono,
            email: cliente.email,
        }
        if (id) {
            ClienteService.actualizar(id, clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al guardar cliente:', error);
            });
        } else {
            ClienteService.guardar(clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al crear cliente:', error);
            });
        }
    }

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
            EmisorService.eliminar(id).then(() => {
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al eliminar el cliente:', error);
            });
        }
    }

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Cliente - ' : 'Crear cliente'} {cliente.nombre ? cliente.nombre : ""}</Title>
            <Form layout="vertical" onFinish={handleSubmit} style={{maxWidth: '30em'}}>
                <Form.Item label="Nombre" required>
                    <Input
                        value={cliente.nombre}
                        onChange={e => setCliente({...cliente, nombre: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="CUIT" required>
                    <Input
                        value={cliente.cuit}
                        onChange={e => setCliente({...cliente, cuit: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Dirección" required>
                    <Input
                        value={cliente.direccion}
                        onChange={e => setCliente({...cliente, direccion: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Teléfono" required>
                    <Input
                        value={cliente.telefono}
                        onChange={e => setCliente({...cliente, telefono: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Email" required>
                    <Input
                        value={cliente.email}
                        onChange={e => setCliente({...cliente, email: e.target.value})}
                    />
                </Form.Item>

                <Button type="primary" htmlType="submit">Guardar</Button>
                {id && (
                    <Button type="danger" onClick={handleDelete} style={{marginLeft: '10px'}}>Eliminar</Button>
                )}
            </Form>
        </div>
    );
};

export default ClienteForm;
