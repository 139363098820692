import React, {useState, useEffect} from 'react';
import AlquilerService from '../services/AlquilerService';
import ClienteService from '../services/ClienteService';
import EmisorService from '../services/EmisorService';
import EmpresaService from '../services/EmpresaService';
import ContenedorService from '../services/ContenedorService';
import ModeloContratoService from '../services/ModeloContratoService';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Button, Checkbox, Select, DatePicker, Typography, Divider, List, message} from 'antd';
import moment from 'moment';

const AlquilerForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {Title} = Typography;
    const {Option} = Select;

    const [alquiler, setAlquiler] = useState({
        clienteId: '',
        empresaId: '',
        contenedorId: '',
        emisorId: '',
        fechaInicio: '',
        fechaFin: '',
        ubicacion: '',
        cancelado: '',
        facturable: '',
        facturaAutomatica: '',
        fechaCancelacion: '',
        montoInicial: ''
    });

    const [clientes, setClientes] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [emisores, setEmisores] = useState([]);
    const [contenedores, setContenedores] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [modelosContrato, setModelosContrato] = useState([]);
    const [modeloElegido, setModeloElegido] = useState([]);

    useEffect(() => {
        // Cargar lista de clientes
        ClienteService.obtenerTodos().then(response => {
            setClientes(response.data);
        }).catch(error => {
            message.error('Error al obtener clientes:', error);
        });

        // Cargar lista de empresas
        EmpresaService.obtenerTodos().then(response => {
            setEmpresas(response.data);
        }).catch(error => {
            message.error('Error al obtener empresas:', error);
        });

        // Cargar lista de emisores
        EmisorService.obtenerTodos().then(response => {
            setEmisores(response.data);
        }).catch(error => {
            message.error('Error al obtener emisores:', error);
        });

        // Cargar lista de contenedores
        ContenedorService.obtenerTodos().then(response => {
            setContenedores(response.data);
        }).catch(error => {
            message.error('Error al obtener containers:', error);
        });

        //Lista de facturas del alquiler
        AlquilerService.obtenerFacturas(id).then(response => {
            setFacturas(response.data);
        }).catch(error => {
            message.error('Error al obtener facturas:', error);
        })

        ModeloContratoService.obtenerTodos().then(response => {
            setModelosContrato(response.data);
        }).catch(error => {
            message.error('Error al obtener modelosContrato:', error);
        });

        // Si existe un ID, cargar el alquiler para editarlo
        if (id) {
            AlquilerService.obtenerPorId(id).then(response => {
                setAlquiler({
                    id: response.data.id,
                    clienteId: response.data.cliente.id ? response.data.cliente.id : '',
                    empresaId: response.data.empresa ? response.data.empresa.id : '',
                    contenedorId: response.data.contenedor.id ? response.data.contenedor.id : '',
                    emisorId: response.data.emisor.id ? response.data.emisor.id : '',
                    fechaInicio: response.data.fechaInicio,
                    fechaFin: response.data.fechaFin,
                    ubicacion: response.data.ubicacion,
                    //cancelado: response.data.cancelado,
                    facturable: response.data.facturable,
                    facturaAutomatica: response.data.facturaAutomatica,
                    fechaCancelacion: alquiler.fechaCancelacion,
                    montoInicial: response.data.montoInicial,
                });
            }).catch(error => {
                message.error('Error al obtener alquiler:', error);
            });
        }
    }, [id]);

    const handleSubmit = (event) => {
        const alquilerData = {
            cliente: {id: alquiler.clienteId},
            empresa: {id: alquiler.empresaId},
            contenedor: {id: alquiler.contenedorId},
            emisor: {id: alquiler.emisorId},
            fechaInicio: alquiler.fechaInicio,
            fechaFin: alquiler.fechaFin,
            ubicacion: alquiler.ubicacion,
            cancelado: alquiler.cancelado,
            facturable: alquiler.facturable,
            facturaAutomatica: alquiler.facturaAutomatica,
            fechaCancelacion: alquiler.fechaCancelacion,
            montoInicial: alquiler.montoInicial,
        };
        if (id) {
            AlquilerService.actualizar(id, alquilerData).then(() => {
                navigate('/alquileres');
            }).catch(error => {
                message.error('Error saving alquiler:', error);
            });
        } else {
            AlquilerService.guardar(alquilerData).then(() => {
                navigate('/alquileres');
            }).catch(error => {
                message.error('Error saving alquiler:', error);
            });
        }

    };

    const handleVerCliente = () => {
        if (alquiler.clienteId) {
            navigate(`/clientes/${alquiler.clienteId}`);
        } else {
            alert('Por favor, seleccione un cliente primero.');
        }
    };

    const handleVerEmpresa = () => {
        if (alquiler.empresaId) {
            navigate(`/empresas/${alquiler.empresaId}`);
        } else {
            alert('Por favor, seleccione una empresa primero.');
        }
    };

    const handleVerContenedor = () => {
        if (alquiler.contenedorId) {
            navigate(`/contenedores/${alquiler.contenedorId}`);
        } else {
            alert('Por favor, seleccione un contenedor primero.');
        }
    };

    const handleVerFactura = (facturaId) => {
        if (id && facturaId) {
            navigate(`/facturas/${facturaId}`);
        }
    };

    const handleVerEmisor = (emisorId) => {
        if (id && emisorId) {
            navigate(`/emisores/${emisorId}`);
        }
    };

    const handleVerUbicacionGoogleMaps = () => {
        if (alquiler.ubicacion) {
            window.location.href = `https://www.google.com/maps/search/${alquiler.ubicacion}`;
        } else {
            alert('Sin ubicación');
        }
    };

    const handleGenerarContrato = () => {
        if (modeloElegido.id) {
            AlquilerService.verContrato(id, modeloElegido.id)
                .then(() => {
                    // Redirige a la ruta que muestra el contrato
                    navigate(`/alquileres/${id}/contrato/${modeloElegido.id}/ver`);
                })
                .catch(error => {
                    message.error('Error al generar contrato:', error);
                });
        } else {
            alert('Elegí un modelo');
        }
    };

    const handleNuevaFactura = () => {
        if (id) {
            navigate(`/facturas/nuevo/alquiler/${alquiler.id}`);
        }
    };

    const handleEditarContrato = () => {
        if (modeloElegido.id) {
            navigate(`/modelosContrato/${modeloElegido.id}`);
        } else {
            alert('Elegí un modelo');
        }

    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={3}>{id ? 'Editar Alquiler' : 'Nuevo Alquiler'}</Title>

            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Cliente">
                    <Select
                        value={alquiler.clienteId}
                        onChange={value => setAlquiler({...alquiler, clienteId: value})}
                        placeholder="Seleccione un Cliente"
                    >
                        {clientes.map(cliente => (
                            <Option key={cliente.id} value={cliente.id}>
                                {cliente.nombre} {cliente.empresa?.razonSocial && `de (${cliente.empresa.razonSocial})`}
                            </Option>
                        ))}
                    </Select>
                    <Button type="link" onClick={handleVerCliente}>Ver Cliente</Button>
                </Form.Item>

                <Form.Item label="Empresa">
                    <Select
                        value={alquiler.empresaId}
                        onChange={value => setAlquiler({...alquiler, empresaId: value})}
                        placeholder="Seleccione una empresa"
                    >
                        {empresas.map(empresa => (
                            <Option key={empresa.id} value={empresa.id}>
                                {empresa.razonSocial}
                            </Option>
                        ))}
                    </Select>
                    <Button type="link" onClick={handleVerEmpresa}>Ver Empresa</Button>
                </Form.Item>

                <Form.Item label="Contenedor">
                    <Select
                        value={alquiler.contenedorId}
                        onChange={value => setAlquiler({...alquiler, conenadorId: value})}
                        placeholder="Seleccione un Contenedor"
                    >
                        {contenedores.map(contenedor => (
                            <Option key={contenedor.id} value={contenedor.id}>{contenedor.codigo}</Option>
                        ))}
                    </Select>
                    <Button type="link" onClick={handleVerContenedor}>Ver Contenedor</Button>
                </Form.Item>

                <Form.Item label="Fecha de Inicio">
                    <DatePicker
                        style={{width: '100%'}}
                        value={alquiler.fechaInicio ? moment(alquiler.fechaInicio) : null} // Convertir a moment
                        onChange={date => setAlquiler({...alquiler, fechaInicio: date ? date.toISOString() : ''})}
                    />
                </Form.Item>

                <Form.Item label="Fecha de Fin">
                    <DatePicker
                        style={{width: '100%'}}
                        value={alquiler.fechaFin ? moment(alquiler.fechaFin) : null} // Convertir a moment
                        onChange={date => setAlquiler({...alquiler, fechaFin: date ? date.toISOString() : ''})}
                    />
                </Form.Item>

                <Form.Item label="Ubicación">
                    <Input
                        value={alquiler.ubicacion}
                        onChange={e => setAlquiler({...alquiler, ubicacion: e.target.value})}
                    />
                    <Button type="link" onClick={handleVerUbicacionGoogleMaps}>Ver en Google Maps</Button>
                </Form.Item>

                <Form.Item label="Monto Inicial">
                    <Input
                        type="text"
                        value={alquiler.montoInicial}
                        onChange={e => setAlquiler({...alquiler, montoInicial: e.target.value})}
                    />
                </Form.Item>

                <Form.Item>
                    <Checkbox
                        checked={alquiler.cancelado}
                        onChange={e => setAlquiler({...alquiler, cancelado: e.target.checked})}
                    >
                        Cancelado / terminado
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Checkbox
                        checked={alquiler.facturable}
                        onChange={e => setAlquiler({...alquiler, facturable: e.target.checked})}
                    >
                        Con factura
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Checkbox
                        checked={alquiler.facturaAutomatica}
                        onChange={e => setAlquiler({...alquiler, facturaAutomatica: e.target.checked})}
                    >
                        Factura automática
                    </Checkbox>
                </Form.Item>

                <Form.Item label="Emisor de factura">
                    <Select
                        value={alquiler.emisorId}
                        onChange={value => setAlquiler({...alquiler, emisorId: value})}
                        placeholder="Seleccione un emisor"
                    >
                        {emisores.map(emisor => (
                            <Option key={emisor.id} value={emisor.id}>{emisor.razonSocial}</Option>
                        ))}
                    </Select>
                    <Button type="link" onClick={handleVerEmisor}>Ver Emisor</Button>
                </Form.Item>

                <Button type="primary" htmlType="submit" style={{width: '100%'}}>Guardar</Button>
            </Form>

            {id && (
                <>
                    <Divider/>
                    <Title level={4}>Modelo de contrato</Title>
                    <Select
                        style={{width: '100%'}}
                        onChange={value => setModeloElegido({id: value})}
                        placeholder="Seleccione un modelo"
                    >
                        {modelosContrato.map(modeloContrato => (
                            <Option key={modeloContrato.id} value={modeloContrato.id}>{modeloContrato.titulo}</Option>
                        ))}
                    </Select>
                    <Button type="primary" onClick={handleGenerarContrato}>Armar contrato</Button>
                    <Button onClick={handleEditarContrato}>Editar</Button>

                    <Divider/>
                    <Title level={4}>Facturas</Title>
                    <Button type="primary" onClick={handleNuevaFactura}>Crear factura</Button>
                    <List
                        dataSource={facturas}
                        renderItem={factura => (
                            <List.Item key={factura.id}>
                                <Button type="link" onClick={() => handleVerFactura(factura.id)}>
                                    {new Date(factura.fechaEmision).toLocaleString('es-ES', {month: 'long'})} - {new Intl.NumberFormat('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS'
                                }).format(factura.importeNeto)}
                                </Button>
                            </List.Item>
                        )}
                    />
                </>
            )}
        </div>
    );
}
export default AlquilerForm;
