import React, {useState} from 'react';
import logoSecondary from '../../assets/images/logo_secondary.png';
import '../../assets/css/landingStyles.css';

const LoginNavbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(prevState => {
            const newState = !prevState;
            return newState;
        });
    };

    const closeSidebar = () => setSidebarOpen(false);

    return (
        <>
            <nav
                className={`w3-sidebar w3-blue w3-top w3-large w3-padding ${sidebarOpen ? 'open' : 'w3-collapse'}`}
                style={{zIndex: 3, width: 300, fontWeight: 'bold'}}
                id="mySidebar"
            >
                <button onClick={closeSidebar} className="w3-button w3-hide-large w3-display-topleft w3-hover-white"
                        style={{width: '100%', fontSize: '22px'}}>
                    ×
                </button>
                <div className="w3-container">
                    <h3>
                        <img src={logoSecondary} alt="Reefers del Sur" style={{width: '100%'}}/>
                    </h3>
                </div>
                <div className="w3-bar-block">
                    <a href="/" onClick={closeSidebar} className="w3-bar-item w3-button">
                        <i className="bi bi-house-door w3-margin-right"></i> Volver al menú
                    </a>
                </div>
            </nav>

            {/* Menú superior en pantallas pequeñas */}
            <header className="w3-container w3-top w3-hide-large w3-blue w3-xlarge w3-padding"
                    style={{whiteSpace: 'nowrap'}}>
                <button onClick={toggleSidebar} className="w3-button w3-blue w3-margin-right w3-hover-white">☰</button>
                <span className="w3-xxlarge">Reefers del Sur</span>
            </header>


            {/* Efecto de superposición al abrir el sidebar en pantallas pequeñas */}
            {sidebarOpen && (
                <div className="w3-overlay w3-hide-large w3-hover-white" onClick={closeSidebar}
                     style={{cursor: 'pointer'}}
                     title="close side menu"></div>
            )}

        </>
    );
};

export default LoginNavbar;
