import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmpresaService from '../services/EmpresaService';
import { List, Typography, Button, message } from 'antd';

const { Title } = Typography;

const EmpresaList = () => {
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    EmpresaService.obtenerTodos().then(response => {
      setEmpresas(response.data);
    }).catch(error => {
      message.error('Error fetching empresas:', error);
      message.error('Error al obtener la lista de empresas');
    });
  }, []);

  return (
      <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
          <Title level={2}>Lista de Empresas</Title>
          <List
              bordered
              dataSource={empresas}
              renderItem={empresa => (
                  <List.Item>
                      <Link to={`/empresas/${empresa.id}`}>{empresa.razonSocial}</Link>
                  </List.Item>
              )}
          />
          <div style={{marginTop: '1rem'}}>
              <Link to="/empresas/nuevo">
                  <Button type="primary">Crear nueva empresa</Button>
              </Link>
          </div>
      </div>
  );
}

export default EmpresaList;
