import React, { useState, useEffect } from 'react';
import { List, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import FacturaService from '../services/FacturaService';

const { Title } = Typography;

const FacturaList = () => {
    const [facturas, setFacturas] = useState([]);

    useEffect(() => {
        FacturaService.obtenerTodos()
            .then(response => setFacturas(response.data))
            .catch(error => message.error('Error fetching facturas:', error));
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Lista de Facturas</Title>
            <List
                bordered
                dataSource={facturas}
                renderItem={factura => (
                    <List.Item>
                        <Link to={`/facturas/${factura.id}`}>
                            {factura.fechaEmision} - {new Intl.NumberFormat('es-AR', {
                            style: 'currency',
                            currency: 'ARS'
                        }).format(factura.importeTotal)}
                        </Link>
                    </List.Item>
                )}
            />
            <Link to="/facturas/nuevo">
                <Button type="primary" style={{marginTop: 16}}>Crear</Button>
            </Link>
        </div>
    );
};

export default FacturaList;
