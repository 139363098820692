// src/App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import LoginPage from './components/LoginPage';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';

import ModeloContratoList from './components/ModeloContratoList';
import ModeloContratoForm from './components/ModeloContratoForm';

import ComentarioList from './components/ComentarioList';
import ComentarioForm from './components/ComentarioForm';

import AlquilerList from './components/AlquilerList';
import AlquilerForm from './components/AlquilerForm';
import ContratoDoc from './components/ContratoDoc';

import ClienteList from './components/ClienteList';
import ClienteForm from './components/ClienteForm';

import EmpresaList from './components/EmpresaList';
import EmpresaForm from './components/EmpresaForm';

import CobranzaList from './components/CobranzaList';
import CobranzaForm from './components/CobranzaForm';

import ContenedorList from './components/ContenedorList';
import ContenedorForm from './components/ContenedorForm';

import FacturaList from './components/FacturaList';
import FacturaForm from './components/FacturaForm';
import FacturaCDoc from './components/FacturaCDoc';

import ReporteList from './components/ReporteList';
import ReporteForm from './components/ReporteForm';

import EmisorList from './components/EmisorList';
import EmisorForm from './components/EmisorForm';

import PresupuestoList from './components/PresupuestoList';
import PresupuestoForm from './components/PresupuestoForm';
import PresupuestoDoc from './components/PresupuestoDoc';


import PrivateRoute from './components/PrivateRoute';
import ProtectedLayout from './components/layout/ProtectedLayout';
import PrintableProtectedLayout from './components/layout/PrintableProtectedLayout';

function App() {
    return (
        <Router>
            <Routes>

                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/" element={<LandingPage/>}/>

                <Route element={<PrivateRoute><PrintableProtectedLayout /></PrivateRoute>}>
                    {/* Documentos */}
                    <Route path="/facturas/:id/ver" element={<FacturaCDoc/>}/>
                    <Route path="/alquileres/:id/contrato/:idContrato/ver" element={<ContratoDoc/>}/>
                    <Route path="/presupuestos/:id/ver" element={<PresupuestoDoc/>}/>

                </Route>
                <Route element={<PrivateRoute><ProtectedLayout/></PrivateRoute>}>

                    <Route path="/home" element={<HomePage/>}/>

                    {/* Modelos de contrato Routes */}
                    <Route path="/modelosContrato" element={<ModeloContratoList/>}/>
                    <Route path="/modelosContrato/nuevo" element={<ModeloContratoForm/>}/>
                    <Route path="/modelosContrato/:id" element={<ModeloContratoForm/>}/>

                    {/* Comentario Routes */}
                    <Route path="/comentarios" element={<ComentarioList/>}/>
                    <Route path="/comentarios/nuevo" element={<ComentarioForm/>}/>
                    <Route path="/comentarios/:id" element={<ComentarioForm/>}/>

                    {/* Alquiler Routes */}
                    <Route path="/alquileres" element={<AlquilerList/>}/>
                    <Route path="/alquileres/nuevo" element={<AlquilerForm/>}/>
                    <Route path="/alquileres/:id" element={<AlquilerForm/>}/>


                    {/* Cliente Routes */}
                    <Route path="/clientes" element={<ClienteList/>}/>
                    <Route path="/clientes/nuevo" element={<ClienteForm/>}/>
                    <Route path="/clientes/:id" element={<ClienteForm/>}/>

                    {/* Empresa Routes */}
                    <Route path="/empresas" element={<EmpresaList/>}/>
                    <Route path="/empresas/nuevo" element={<EmpresaForm/>}/>
                    <Route path="/empresas/:id" element={<EmpresaForm/>}/>

                    {/* Cobranza Routes */}
                    <Route path="/cobranzas" element={<CobranzaList/>}/>
                    <Route path="/cobranzas/nuevo" element={<CobranzaForm/>}/>
                    <Route path="/cobranzas/:id" element={<CobranzaForm/>}/>

                    {/* Contenedor Routes */}
                    <Route path="/contenedores" element={<ContenedorList/>}/>
                    <Route path="/contenedores/nuevo" element={<ContenedorForm/>}/>
                    <Route path="/contenedores/:id" element={<ContenedorForm/>}/>

                    {/* Factura Routes */}
                    <Route path="/facturas" element={<FacturaList/>}/>
                    <Route path="/facturas/nuevo" element={<FacturaForm/>}/>
                    <Route path="/facturas/nuevo/alquiler/:idAlquiler" element={<FacturaForm/>}/>
                    <Route path="/facturas/:id" element={<FacturaForm/>}/>

                    {/* Reporte Routes */}
                    <Route path="/reportes" element={<ReporteList/>}/>
                    <Route path="/reportes/nuevo" element={<ReporteForm/>}/>
                    <Route path="/reportes/:id" element={<ReporteForm/>}/>

                    {/* Emisor Routes */}
                    <Route path="/emisores" element={<EmisorList/>}/>
                    <Route path="/emisores/nuevo" element={<EmisorForm/>}/>
                    <Route path="/emisores/:id" element={<EmisorForm/>}/>

                    {/* Presupuesto Routes */}
                    <Route path="/presupuestos" element={<PresupuestoList/>}/>
                    <Route path="/presupuestos/nuevo" element={<PresupuestoForm/>}/>
                    <Route path="/presupuestos/:id" element={<PresupuestoForm/>}/>

                </Route>

                <Route path="*" element={<Navigate to="/login"/>}/>
            </Routes>
        </Router>
    );
}

export default App;
