import React, { useState, useEffect } from 'react';
import EmisorService from '../services/EmisorService';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';

const { Title } = Typography;

const EmisorForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [emisor, setEmisor] = useState({ razonSocial: '', cuit: '', direccion: '', puntoVenta: '', condicion: '' });

    useEffect(() => {
        if (id) {
            EmisorService.obtenerPorId(id)
                .then(response => setEmisor(response.data))
                .catch(error => message.error('Error fetching emisor:', error));
        }
    }, [id]);

    const handleSubmit = () => {
        const accion = id ? EmisorService.actualizar(id, emisor) : EmisorService.guardar(emisor);

        accion.then(() => {
            message.success(`Emisor ${id ? 'actualizado' : 'creado'} exitosamente`);
            navigate('/emisores');
        }).catch(error => {
            message.error('Error al guardar emisor:', error);
            message.error('Error al guardar el emisor');
        });
    };

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este emisor?')) {
            EmisorService.eliminar(id).then(() => {
                message.success('Emisor eliminado exitosamente');
                navigate('/emisores');
            }).catch(error => {
                message.error('Error al eliminar el emisor:', error);
                message.error('Error al eliminar el emisor');
            });
        }
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Editar Emisor' : 'Crear Emisor'}</Title>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Razón social" required>
                    <Input
                        value={emisor.razonSocial}
                        onChange={e => setEmisor({...emisor, razonSocial: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="CUIT" required>
                    <Input
                        value={emisor.cuit}
                        onChange={e => setEmisor({...emisor, cuit: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Dirección">
                    <Input
                        value={emisor.direccion}
                        onChange={e => setEmisor({...emisor, direccion: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Punto de venta">
                    <Input
                        value={emisor.puntoVenta}
                        onChange={e => setEmisor({...emisor, puntoVenta: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Condición">
                    <Input
                        value={emisor.condicion}
                        onChange={e => setEmisor({...emisor, condicion: e.target.value})}
                    />
                </Form.Item>
                <Button type="primary" htmlType="submit">Guardar</Button>
                {id && (
                    <Button danger onClick={handleDelete} style={{marginLeft: '8px'}}>
                        Eliminar
                    </Button>
                )}
            </Form>
        </div>
    );
}

export default EmisorForm;
