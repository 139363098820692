// src/services/AuthService.js
import axiosInstance from '../utils/axiosInstance';

class AuthService {
  async login(email, password) {
    const response = await axiosInstance.post('/auth/login', { email, password });
    const token = response.data.token;
    if (token) {
      localStorage.setItem('token', token);
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return !!this.getToken();
  }
}

export default new AuthService(); // Exportamos una instancia de AuthService
