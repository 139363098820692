// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/clientes`;

class ClienteService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(Cliente) {
        return axiosInstance.post(API_URL, Cliente);
    }

    actualizar(id, cliente) {
        return axiosInstance.put(`${API_URL}/${id}`, cliente);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }
}

export default new ClienteService();