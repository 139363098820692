// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/contenedores`;

class ContenedorService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(contenedor) {
        return axiosInstance.post(API_URL, contenedor);
    }

    actualizar(id, contenedor) {
        return axiosInstance.put(`${API_URL}/${id}`, contenedor);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    getReportes(id) {
        return axiosInstance.get(`${API_URL}/${id}/reportes`);
    }
}

export default new ContenedorService();