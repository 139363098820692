// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/cobranzas`;

class CobranzaService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(cobranza) {
        return axiosInstance.post(API_URL, cobranza);
    }

    actualizar(id, cobranza) {
        return axiosInstance.put(`${API_URL}/${id}`, cobranza);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }
}

export default new CobranzaService();