import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Select, Button, Typography, message } from 'antd';
import FacturaService from '../services/FacturaService';
import AlquilerService from '../services/AlquilerService';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const FacturaForm = () => {
    const { id, idAlquiler } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [factura, setFactura] = useState({ fechaEmision: '', importeNeto: '', comentarios: '', alquilerId: '' });
    const [alquileres, setAlquileres] = useState([]);
    const [cobranzas, setCobranzas] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        AlquilerService.obtenerTodos().then(response => setAlquileres(response.data)).catch(message.error);

        if (id) {
            FacturaService.obtenerPorId(id).then(response => {
                const data = response.data;
                setFactura({
                    ...data,
                    alquilerId: data.alquiler ? data.alquiler.id : '',
                    fechaEmision: data.fechaEmision ? dayjs(data.fechaEmision) : ''
                });
                form.setFieldsValue({
                    fechaEmision: data.fechaEmision ? dayjs(data.fechaEmision) : '',
                    importeNeto: data.importeNeto,
                    comentarios: data.comentarios,
                    alquilerId: data.alquiler ? data.alquiler.id : ''
                });
            }).catch(message.error);

            FacturaService.obtenerCobranzas(id).then(response => setCobranzas(response.data)).catch(message.error);
        } else if (idAlquiler) {
            setFactura(prev => ({ ...prev, alquilerId: idAlquiler }));
            form.setFieldsValue({ alquilerId: idAlquiler });
        }
    }, [id, idAlquiler, form]);

    const handleSubmit = (values) => {
        const facturaData = {
            ...values,
            fechaEmision: values.fechaEmision ? values.fechaEmision.toISOString() : new Date().toISOString()
        };

        const serviceCall = id ? FacturaService.actualizar(id, facturaData) : FacturaService.guardar(facturaData);
        serviceCall.then(() => {
            message.success(id ? 'Factura actualizada' : 'Factura creada');
            navigate('/facturas');
        }).catch(error => message.error(`Error: ${error.message}`));
    };

    const handleFacturar = async () => {
        try {
            await FacturaService.facturar(id, factura);
            message.success('Factura generada con éxito.');
            navigate(`/facturas/${id}/ver`);
        } catch (error) {
            message.error(`Error al generar la factura: ${error.message}`);
        }
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Editar Factura' : 'Crear Factura'}</Title>
            {id && (
                <div>
                    {factura.isFacturado ? (
                        <>
                            <Text type="success">FACTURA GENERADA</Text>
                            <Link to={`${location.pathname}/ver`}>
                                <Button type="link">Ver factura realizada</Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Text type="danger">SIN FACTURAR</Text>
                            <Button onClick={handleFacturar} type="primary">Facturar</Button>
                        </>
                    )}
                </div>
            )}
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Fecha emisión" name="fechaEmision">
                    <DatePicker/>
                </Form.Item>
                <Form.Item label="Importe Neto" name="importeNeto"
                           rules={[{required: true, message: 'Ingrese el importe neto'}]}>
                    <Input type="number"/>
                </Form.Item>
                <Form.Item label="Comentarios" name="comentarios">
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item label="Alquiler" name="alquilerId"
                           rules={[{required: true, message: 'Seleccione un alquiler'}]}>
                    <Select placeholder="Seleccione un Alquiler">
                        {alquileres.map(alquiler => (
                            <Select.Option key={alquiler.id} value={alquiler.id}>
                                {alquiler.cliente.nombre} - {alquiler.contenedor.codigo}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">Guardar</Button>
                {id && <Button onClick={() => navigate(`/facturas/${id}/ver`)}>Ver</Button>}
            </Form>
            {id && (
                <div>
                    <Title level={3}>Cobranzas</Title>
                    {cobranzas.map(cobranza => (
                        <Button key={cobranza.id} onClick={() => navigate(`/cobranzas/${cobranza.id}`)}>
                            {new Date(cobranza.fechaPago).toLocaleDateString()} - {new Intl.NumberFormat('es-AR', {
                            style: 'currency',
                            currency: 'ARS'
                        }).format(cobranza.montoPagado)}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FacturaForm;
