import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CobranzaService from '../services/CobranzaService';
import { List, Typography, Button, message } from 'antd';

const { Title } = Typography;

const CobranzaList = () => {
  const [cobranzas, setCobranzas] = useState([]);

  useEffect(() => {
    CobranzaService.obtenerTodos().then(response => {
      setCobranzas(response.data);
    }).catch(error => {
      message.error('Error fetching cobranzas:', error);
      message.error('Error al obtener la lista de cobranzas');
    });
  }, []);

  return (
      <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
          <Title level={2}>Lista de Cobranzas</Title>
          <List
              bordered
              dataSource={cobranzas}
              renderItem={cobranza => (
                  <List.Item>
                      <Link to={`/cobranzas/${cobranza.id}`}>${cobranza.montoPagado} - {cobranza.fechaPago}</Link>
                  </List.Item>
              )}
          />
          <div style={{marginTop: '1rem'}}>
              <Link to="/cobranzas/nuevo">
                  <Button type="primary">Crear nueva cobranza</Button>
              </Link>
          </div>
      </div>
  );
}

export default CobranzaList;
