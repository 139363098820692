import React, { useState, useEffect } from 'react';
import CobranzaService from '../services/CobranzaService';
import FacturaService from '../services/FacturaService';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, Typography, message } from 'antd';

const { Title } = Typography;

const CobranzaForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [cobranza, setCobranza] = useState({ facturaId: '', fechaPago: '', montoPagado: '' });
    const [facturas, setFacturas] = useState([]);

    useEffect(() => {
        FacturaService.obtenerTodos()
            .then(response => setFacturas(response.data))
            .catch(error => message.error('Error fetching facturas:', error));

        if (id) {
            CobranzaService.obtenerPorId(id)
                .then(response => setCobranza({
                    facturaId: response.data.factura.id,
                    fechaPago: response.data.fechaPago,
                    montoPagado: response.data.montoPagado,
                }))
                .catch(error => message.error('Error fetching cobranza:', error));
        }
    }, [id]);

    const handleSubmit = () => {
        const cobranzaData = {
            factura: { id: cobranza.facturaId },
            montoPagado: cobranza.montoPagado,
            fechaPago: cobranza.fechaPago,
        };

        const accion = id ? CobranzaService.actualizar(id, cobranzaData) : CobranzaService.guardar(cobranzaData);

        accion.then(() => {
            message.success(`Cobranza ${id ? 'actualizada' : 'creada'} exitosamente`);
            navigate('/cobranzas');
        }).catch(error => {
            message.error('Error al guardar cobranza:', error);
            message.error('Error al guardar cobranza');
        });
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Editar Cobranza' : 'Crear Cobranza'}</Title>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Monto" required>
                    <Input
                        type="number"
                        value={cobranza.montoPagado}
                        onChange={e => setCobranza({...cobranza, montoPagado: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Fecha" required>
                    <Input
                        type="date"
                        value={cobranza.fechaPago}
                        onChange={e => setCobranza({...cobranza, fechaPago: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Factura" required>
                    <Select
                        placeholder="Seleccione factura"
                        value={cobranza.facturaId}
                        onChange={value => setCobranza({...cobranza, facturaId: value})}
                    >
                        {facturas.map(factura => (
                            <Select.Option key={factura.id} value={factura.id}>
                                {factura.fechaEmision} - {factura.monto}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">Guardar</Button>
                {cobranza.facturaId && (
                    <Button onClick={() => navigate(`/contenedores/${cobranza.facturaId}`)} style={{marginLeft: '8px'}}>
                        Ver
                    </Button>
                )}
            </Form>
        </div>
    );
}

export default CobranzaForm;
