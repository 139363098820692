// src/components/LoginPage.js
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, Input, Button, Typography, Alert} from 'antd';
import AuthService from '../services/AuthService';
import LoginNavbar from "./layout/LoginNavbar";

const {Title} = Typography;

function LoginPage() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        setError(null);
        const {email, password} = values;

        try {
            await AuthService.login(email, password);
            navigate('/home');
        } catch (err) {
            setError('Credenciales incorrectas. Inténtalo de nuevo.');
        }
    };

    return (
        <>
            <LoginNavbar/>
            <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
                <Title level={2}>Iniciar Sesión</Title>
                <Form onFinish={handleSubmit}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Por favor ingresa tu email'}]}
                    >
                        <Input type="email"/>
                    </Form.Item>

                    <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[{required: true, message: 'Por favor ingresa tu contraseña'}]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ingresar
                        </Button>
                    </Form.Item>

                    {error && (
                        <Form.Item>
                            <Alert message={error} type="error" showIcon/>
                        </Form.Item>
                    )}
                </Form>
            </div>
        </>
    );
}

export default LoginPage;
