import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmisorService from '../services/EmisorService';
import { List, Typography, Button, message } from 'antd';

const { Title } = Typography;

const EmisorList = () => {
  const [emisores, setEmisores] = useState([]);

  useEffect(() => {
    EmisorService.obtenerTodos().then(response => {
      setEmisores(response.data);
    }).catch(error => {
      message.error('Error fetching emisores:', error);
      message.error('Error al obtener la lista de emisores');
    });
  }, []);

  return (
      <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
          <Title level={2}>Personal</Title>
          <List
              bordered
              dataSource={emisores}
              renderItem={emisor => (
                  <List.Item>
                      <Link to={`/emisores/${emisor.id}`}>{emisor.razonSocial}</Link>
                  </List.Item>
              )}
          />
          <div style={{marginTop: '1rem'}}>
              <Link to="/emisores/nuevo">
                  <Button type="primary">Nuevo emisor</Button>
              </Link>
          </div>
      </div>
  );
}

export default EmisorList;
