// src/components/ContenedorList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, message, List} from 'antd';
import ContenedorService from '../services/ContenedorService';

const { Title } = Typography;

const ContenedorList = () => {
  const [contenedores, setContenedores] = useState([]);

  useEffect(() => {
    ContenedorService.obtenerTodos()
      .then((response) => setContenedores(response.data))
      .catch((error) => message.error('Error al obtener contenedores:', error));
  }, []);

  return (
      <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
          <Title level={2}>Contenedores</Title>
          <List
              bordered
              dataSource={contenedores}
              renderItem={contenedor => (
                  <List.Item>
                      <Link to={`/contenedores/${contenedor.id}`}>{contenedor.codigo}</Link>
                  </List.Item>
              )}
          />
          <div style={{marginTop: '1rem'}}>
              <Link to="/contenedores/nuevo">
                  <Button type="primary">Nuevo contenedor</Button>
              </Link>
          </div>
      </div>
  );
};

export default ContenedorList;
