// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/reportes`;

class ReporteService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(reporte) {
        return axiosInstance.post(API_URL, reporte);
    }

    actualizar(id, reporte) {
        return axiosInstance.put(`${API_URL}/${id}`, reporte);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }
}

export default new ReporteService();