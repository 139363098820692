// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

function PrivateRoute({ children }) {
  return AuthService.isAuthenticated() ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
