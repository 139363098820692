// src/components/ContenedorForm.js
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Select, Checkbox, Button, Typography, Space, List, Card, message} from 'antd';
import ContenedorService from '../services/ContenedorService';
import EmisorService from '../services/EmisorService';

const {Title} = Typography;
const {Option} = Select;

const ContenedorForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [reportes, setReportes] = useState([]);
    const [contenedor, setContenedor] = useState({
        codigo: '',
        tipo: '',
        clase: '',
        longitud: '',
        descripcion: '',
        disponible: true,
    });

    useEffect(() => {
        if (id) {
            ContenedorService.obtenerPorId(id)
                .then((response) => setContenedor(response.data))
                .catch((error) => message.error('Error fetching contenedor:', error));

            ContenedorService.getReportes(id)
                .then((response) => setReportes(response.data))
                .catch((error) => message.error('Error fetching containers:', error));
        }
    }, [id]);

    const handleSubmit = () => {
        if (id) {
            ContenedorService.actualizar(id, contenedor)
                .then(() => navigate('/contenedores'))
                .catch((error) => message.error('Error al guardar contenedor:', error));
        } else {
            ContenedorService.guardar(contenedor)
                .then(() => navigate('/contenedores'))
                .catch((error) => message.error('Error al crear contenedor:', error));
        }
    };

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este contenedor?')) {
            EmisorService.eliminar(id)
                .then(() => navigate('/contenedores'))
                .catch((error) => message.error('Error al eliminar el contenedor:', error));
        }
    };

    const handleNuevoReporte = () => {
        if (id) {
            navigate(`/reportes/nuevo`);
        }
    };

    const tipos = ['REEFER', 'SECO'];
    const clases = ['HIGH_CUBE', 'LOW_CUBE', 'OTRO'];
    const longitudes = ['VEINTE_PIES', 'CUARENTA_PIES', 'OTRO'];

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Contenedor ' + contenedor.codigo : 'Crear Contenedor'}</Title>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Código">
                    <Input
                        value={contenedor.codigo}
                        onChange={(e) => setContenedor({...contenedor, codigo: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Tipo">
                    <Select
                        value={contenedor.tipo}
                        onChange={(value) => setContenedor({...contenedor, tipo: value})}
                    >
                        {tipos.map((tipo) => (
                            <Option key={tipo} value={tipo}>
                                {tipo}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Clase">
                    <Select
                        value={contenedor.clase}
                        onChange={(value) => setContenedor({...contenedor, clase: value})}
                    >
                        {clases.map((clase) => (
                            <Option key={clase} value={clase}>
                                {clase}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Longitud">
                    <Select
                        value={contenedor.longitud}
                        onChange={(value) => setContenedor({...contenedor, longitud: value})}
                    >
                        {longitudes.map((longitud) => (
                            <Option key={longitud} value={longitud}>
                                {longitud}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Descripción">
                    <Input.TextArea
                        value={contenedor.descripcion}
                        onChange={(e) => setContenedor({...contenedor, descripcion: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Disponible" valuePropName="checked">
                    <Checkbox
                        checked={contenedor.disponible}
                        onChange={(e) => setContenedor({...contenedor, disponible: e.target.checked})}
                    >
                        Disponible
                    </Checkbox>
                </Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                    {id && (
                        <Button danger onClick={handleDelete}>
                            Eliminar
                        </Button>
                    )}
                    <Button onClick={() => navigate('/contenedores')}>Volver</Button>
                </Space>
            </Form>

            {id && (
                <div style={{marginTop: '20px'}}>
                    <Title level={3}>Reportes</Title>
                    <Button type="dashed" onClick={handleNuevoReporte}>
                        Crear nuevo reporte
                    </Button>
                    <List
                        dataSource={reportes}
                        renderItem={(reporte) => (
                            <List.Item>
                                <Card>
                                    <p>{reporte.fechaReporte}</p>
                                    <p>{reporte.resuelto ? 'Resuelto' : 'Pendiente'}</p>
                                    <p>{reporte.descripcion}</p>
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default ContenedorForm;
