// src/components/EmpresaForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Form, Input, Button, Typography, message} from 'antd';
import EmpresaService from '../services/EmpresaService';

const { Title } = Typography;

const EmpresaForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [empresa, setEmpresa] = useState({ razonSocial: '', direccion: '', cuit: '' });

  useEffect(() => {
    if (id) {
      EmpresaService.obtenerPorId(id)
        .then((response) => setEmpresa(response.data))
        .catch((error) => message.error('Error fetching empresa:', error));
    }
  }, [id]);

  const handleSubmit = () => {
    if (id) {
      EmpresaService.actualizar(id, empresa)
        .then(() => {
          navigate('/empresas');
          message.success('Empresa actualizada exitosamente');
        })
        .catch((error) => message.error('Error al guardar empresa:', error));
    } else {
      EmpresaService.guardar(empresa)
        .then(() => {
          navigate('/empresas');
          message.success('Empresa creada exitosamente');
        })
        .catch((error) => message.error('Error al crear empresa:', error));
    }
  };

  return (
      <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
        <Title level={2}>{id ? 'Editar Empresa' : 'Crear Empresa'}</Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Razón Social" required>
            <Input
                value={empresa.razonSocial}
                onChange={(e) => setEmpresa({...empresa, razonSocial: e.target.value})}
            />
          </Form.Item>
          <Form.Item label="Dirección" required>
            <Input
                value={empresa.direccion}
                onChange={(e) => setEmpresa({...empresa, direccion: e.target.value})}
            />
          </Form.Item>
          <Form.Item label="CUIT">
            <Input
                value={empresa.cuit}
                onChange={(e) => setEmpresa({...empresa, cuit: e.target.value})}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
          <Button onClick={() => navigate('/empresas')} style={{marginLeft: '10px'}}>
            Volver
          </Button>
        </Form>
      </div>
  );
};

export default EmpresaForm;
